import * as React from 'react';
import { IRecommendations } from '../../types/newbuildingRecommendations';

import { LinkButton } from '@cian/ui-kit';
import { useNewbuildingRecommendations } from './useNewbuildingRecommendations';
import { ELoadingStatus } from '../../types/loadingStatus';
import { ActionAfterViewed } from '@cian/action-after-viewed-component';
import { RecommendationCard } from '../../components/NewbuildingRecommendationMobile';
import { RecommendationReview } from '../../components/NewbuildingRecommendationMobile/RecommendationReview';
import { RecommendationsSection } from '../../components/NewbuildingRecommendationMobile/RecommendationsSection/RecommendationsSection';

export const NewbuildingRecommendations = () => {
  const { newbuildings, onRecommendationsShow, onShowAllClick, recommendationsStatus, onCardClick } =
    useNewbuildingRecommendations();
  const { recommendations, allRecommendations } = newbuildings;

  if (recommendationsStatus !== ELoadingStatus.Success || !recommendations.length) {
    return null;
  }

  return (
    <ActionAfterViewed callback={onRecommendationsShow}>
      <RecommendationsSection
        title="Подобрали ЖК для вас"
        watchAll={
          <LinkButton fullWidth={true} href={allRecommendations?.link} onClick={onShowAllClick} target="_blank">
            Смотреть все предложения
          </LinkButton>
        }
      >
        {recommendations.map((item: IRecommendations, idx) => {
          return (
            <RecommendationCard
              url={item.url}
              key={`recommendation-${idx}`}
              name={item.name}
              status={item.status}
              minPrice={item.minPrice || ''}
              mediaUrl={item.media[0] ? item.media[0].url : ''}
              geo={item.underground ? `м. ${item.underground.undergroundName}` : item.address}
              reviews={item.review.reviewCount ? <RecommendationReview review={item.review} /> : null}
              onClick={onCardClick({ newbuildingId: item.id, position: idx })}
            />
          );
        })}
      </RecommendationsSection>
    </ActionAfterViewed>
  );
};
