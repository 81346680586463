import * as React from 'react';
import { Recommendation } from '../../../components/Recommendation';
import { FavoriteButtonContainer } from '../../FavoriteButtonContainer';
import { LabelsContainer } from '../../LabelsContainer';
import { RecommendationsList } from '../../../components/RecommendationsList';

import type { useRecommendations } from '../useRecommendations';
import { NewbuildingRecommendations } from '../../NewbuildingRecommendationsMobile/NewbuildingRecommendations';
import { useSelector } from 'react-redux';
import { useApplicationContext } from '../../../utils/applicationContext';
import { isNewbuildingRecommendationsMobileEnabledSelector } from '../../../selectors/newbuildings';

type TRecommendationsListMobileProps = {
  onTrack: (offerId: number | null, position: number) => void;
  recommendationsControls: ReturnType<typeof useRecommendations>;
};

const NEWBUILDINGS_RECOMMENDATIONS_PLACEMENT_INDEX = 4;

export const RecommendationsListMobile: React.FC<TRecommendationsListMobileProps> = props => {
  const { config } = useApplicationContext();
  const showRecommendations =
    useSelector(isNewbuildingRecommendationsMobileEnabledSelector) ||
    config.get('NewbuildingRecommendations.ShowOnVerticals.Enabled');

  const {
    recommendationsControls: {
      onShow,
      showHeader,
      onMoreClick,
      recommendations,
      onRecommendationClick,
      onShowTrap,
      isOfferVisited,
    },
    onTrack,
  } = props;
  const splitRecommendations =
    showRecommendations && recommendations.length > NEWBUILDINGS_RECOMMENDATIONS_PLACEMENT_INDEX;
  const recommendationsPart1 = splitRecommendations
    ? recommendations.slice(0, NEWBUILDINGS_RECOMMENDATIONS_PLACEMENT_INDEX)
    : recommendations;
  const recommendationsPart2 = splitRecommendations
    ? recommendations.slice(NEWBUILDINGS_RECOMMENDATIONS_PLACEMENT_INDEX)
    : [];

  return (
    <>
      <RecommendationsList
        onShow={onShow}
        onMoreClick={onMoreClick}
        withAction={splitRecommendations ? false : true}
        withHeader={showHeader}
      >
        {recommendationsPart1.map((recommendation, index) => (
          <Recommendation
            onTrack={onTrack}
            position={index + 1}
            key={`recommendation-${index}`}
            onClick={onRecommendationClick}
            onShowTrap={onShowTrap}
            favoriteButton={<FavoriteButtonContainer size="XS" id={recommendation.offerId} />}
            labels={<LabelsContainer visited={isOfferVisited(recommendation.offerId)} />}
            {...recommendation}
          />
        ))}
      </RecommendationsList>
      {showRecommendations && <NewbuildingRecommendations />}
      {splitRecommendations && (
        <RecommendationsList onShow={onShow} onMoreClick={onMoreClick} withHeader={false}>
          {recommendationsPart2.map((recommendation, index) => (
            <Recommendation
              onTrack={onTrack}
              position={index + 4 + 1}
              key={`recommendation-${index}`}
              onClick={onRecommendationClick}
              onShowTrap={onShowTrap}
              favoriteButton={<FavoriteButtonContainer size="XS" id={recommendation.offerId} />}
              labels={<LabelsContainer visited={isOfferVisited(recommendation.offerId)} />}
              {...recommendation}
            />
          ))}
        </RecommendationsList>
      )}
    </>
  );
};
