import * as React from 'react';
import { UIHeading4, UIHeading5, UIText2 } from '@cian/ui-kit';

import * as styles from './recommendationCard.css';

interface ICardProps {
  name: string;
  mediaUrl: string;
  minPrice: string;
  status: string;
  geo?: string | null;
  reviews: React.ReactElement | null;
  url: string;
  onClick(): void;
}

export const RecommendationCard = ({ name, mediaUrl, minPrice, status, reviews, geo, url, onClick }: ICardProps) => {
  return (
    <li className={styles['slide']}>
      <a
        data-testid="NewbuildingRecommendationCard"
        data-name="NewbuildingRecommendationCard"
        href={url}
        target="_blank"
        className={styles['card']}
        onClick={onClick}
        rel="noreferrer"
      >
        <img className={styles['image']} src={mediaUrl} alt={name} />
        <div className={styles['content']}>
          <div className={styles['title']}>
            <UIHeading4 whiteSpace="nowrap">{name}</UIHeading4>
          </div>
          {!!minPrice && (
            <div className={styles['price']}>
              <UIHeading5>{minPrice}</UIHeading5>
            </div>
          )}
          <div className={styles['details']}>
            {reviews && <div className={styles['reviews']}>{reviews}</div>}
            <div className={styles['status']}>{status}</div>
          </div>

          {geo && (
            <div className={styles['geo']}>
              <UIText2 display="inline" color="text-secondary-default">
                {geo}
              </UIText2>
            </div>
          )}
        </div>
      </a>
    </li>
  );
};
