import { EQueryParameters } from '../../repositories/feed-page/v2/mobile-site/get-recommendations';
import { IAppState } from '../../types';
import { isAbUseExperimentGroup } from '../../utils/isAbUseExperimentGroup';
import { selectLandingType } from '../landingType';
import { selectExperiments } from '../selectExperiments';

export const NEWBUILDING_ENTRY_POINTS = 'newbuilding_entry_points_main_mobile';

export const isNewbuildingRecommendationsMobileEnabledSelector = (state: IAppState): boolean => {
  const landingType = selectLandingType(state);
  const abUseExperiments = selectExperiments(state);
  const isAbEnabled = isAbUseExperimentGroup(abUseExperiments, NEWBUILDING_ENTRY_POINTS, 'B');

  return isAbEnabled && landingType === EQueryParameters.MainPage;
};
