import { ca } from '@cian/analytics';

export const onRecommendationsSectionShowAnalytic = (products: { id: number; position: number }[]) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'newbuilding_carousel',
    action: 'show_sopr',
    label: 'nb_recomend_jk',
    page: {
      pageType: 'Home',
      siteType: 'mobile',
      extra: {
        products,
      },
    },
  });
};

export const onShowAllClickAnalytic = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'newbuilding_carousel',
    action: 'click_sopr',
    label: 'nb_recomend_all',
    page: {
      pageType: 'Home',
      siteType: 'mobile',
    },
  });
};

export const onCardClickAnalytic = ({ newbuildingId, position }: { newbuildingId: number; position: number }) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'newbuilding_carousel',
    action: 'click_sopr',
    label: 'nb_recomend_jk',
    page: {
      pageType: 'Home',
      siteType: 'mobile',
      extra: { to_object: newbuildingId, object_type: 'jk', position },
    },
  });
};
